@font-face {
  font-family: "salsa";
  src: url("./assets/fonts/salsa-regular-webfont.woff2") format("woff2"),
    url("./assets/fonts/salsa-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
a:hover,
a:visited,
a:link,
a:active {
  text-decoration: none;
}
a {
  color: inherit;
}
p {
  text-align: justify;
  line-height: 2em;
  font-weight: 300;
}
h3 {
  font-family: salsa;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 40px;
}
strong,
h5,
h4,
h3,
.navbar-light .navbar-text a.phone {
  color: #364d59;
}

body {
  font-family: "Montserrat", sans-serif;
}
/*To remove border line in iOS devices */
textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="email"],
input[type="submit"],
.input-checkbox {
  -webkit-appearance: none;
  border-radius: 0;
}
.container {
  overflow-x: hidden;
}
.navbar-brand img {
  height: 45px;
  transition: all 300ms ease-in-out;
  transition-property: height, transform;
  transform-origin: left;
}
.navbar {
  box-shadow: 0 3px 5px 0px rgba(0, 0, 0, 0.1);
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
}
.bg-light {
  background-color: rgba(255, 255, 255, 0.7) !important;
}
@-moz-document url-prefix() {
  .bg-light {
    background-color: #fff !important;
    background-image: linear-gradient(165deg, #fff 20%, #eee 100%);
  }
}
.navbar-brand {
  margin: 0;
}
.navbar-expand-md .navbar-nav .nav-link {
  /* padding: 10px 0.5em; */
  line-height: normal;
  padding-bottom: 4px;
  border-bottom: 2px solid transparent;
  transition: color 200ms ease-in-out, border-color 200ms ease;
}
.navbar-light .navbar-nav .nav-link:focus,
.navbar-light .navbar-nav .nav-link:hover {
  color: #14b8ca;
}
.navbar-light .navbar-nav .nav-link.active {
  color: #1292a0;
  /* text-shadow: 0 1px 2px #24333bcc; */
}
.navbar-light .navbar-nav .nav-link.active {
  border-bottom: 2px solid #1292a0;
}

@media (min-width: 992px) {
  .navbar-expand-md .navbar-nav .nav-link {
    padding: 10px 1.4em;
  }
  .navbar-text {
    padding-right: 8vw;
  }
  .navbar-brand {
    padding-left: 8vw;
  }
}
.navbar-light .navbar-text a {
  font-size: 1.1em;
  font-weight: bold;
  transition: color 200ms ease-in-out;
}
.navbar-light .navbar-text a:hover {
  color: #14b8ca;
}
.nav-item {
  display: flex;
  justify-content: center;
}
/* Start Ham Styling */
.navbar-light .navbar-toggler {
  border-color: transparent;
}
.navbar-toggler {
  position: relative;
  padding: 4px;
  width: 50px;
  height: 50px;
}
.navbar-toggler:focus {
  outline: none;
}
.navbar-toggler.collapsed > .ham {
  width: 28px;
}
.navbar-toggler.collapsed > .ham::before,
.navbar-toggler.collapsed > .ham::after {
  transform: translate(0px, 0px) rotate(0deg);
}
.ham,
.ham::before,
.ham::after {
  background-color: #7c7c7d;
  border-radius: 1px;
  width: 28px;
  height: 2px;
  transition: transform 300ms ease-in-out;
}
.ham {
  width: 1px;
  padding: 0;
  margin: 0 auto;
  transition: width 300ms ease-in-out;
}
.ham::before,
.ham::after {
  content: "";
  position: absolute;
  transform-origin: right;
  left: calc(50% - 14px);
}
.ham::before {
  top: 33%;
  transform: translate(-4px, -3px) rotate(-45deg);
}
.ham::after {
  bottom: 33%;
  transform: translate(-4px, 3px) rotate(45deg);
}
/* End Ham Styling */

.carousel {
  font-family: salsa;
  position: relative;
  text-align: center;
  padding-top: 76px;
  height: calc(500px + 62px);
  width: 100%;
  overflow: hidden;
  color: #fff;
}

.carousel-label {
  background-color: rgba(20, 184, 202, 0.8);
  padding: 0 1em;
  font-weight: 300;
  font-size: 35px;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 65vw;
  transform: translate(-60%, 20%);
  transition: opacity 800ms 300ms ease-in, transform 600ms ease;
}
.carousel-label--hero {
  position: absolute;
  font-size: 50px;
  font-weight: 600;
  letter-spacing: 0.1em;
  opacity: 0;
  top: 50%;
  left: 50%;
  width: 80vw;
  transform: translate(-50%, -50%);
  transition: opacity 800ms 300ms ease-in, transform 600ms ease;
  text-shadow: 3px 3px 5px #24333bcc;
  /* 0px 4px 6px rgba(14, 130, 143, 0.7); */
}
.carousel-label--hero span {
  display: inline-block;
  font-size: 25px;
  font-weight: 400;
  letter-spacing: initial;
}
@media (min-width: 375px) {
  .navbar-brand img {
    height: 50px;
    transform: scale(1.2);
  }
  .navbar-brand img:hover {
    transform: scale(1.25);
  }
}

@media (min-width: 720px) {
  .navbar-brand img {
    height: 50px;
    transform: scale(1.6);
  }
  .navbar-brand img:hover {
    transform: scale(1.65);
  }
  .carousel-label {
    font-size: 45px;
    width: initial;
  }
  .navbar-light .navbar-text a {
    font-size: 1.3em;
  }
  .carousel-label--hero span {
    font-size: 30px;
    transform: translateY(-50%);
  }
}
@media (min-width: 768px) {
  .nav-item::after {
    align-self: center;
    color: lightgrey;
    content: "/";
  }
  .nav-item.end::after {
    content: none;
  }
}
.slick-active .carousel-label {
  opacity: 1;
  transform: translate(-50%, 20%);
}
.slick-active .carousel-label--hero {
  opacity: 1;
}
.video--back {
  width: 100%;
  height: 100%;
  background-color: #7fe0e7;
  background-image: linear-gradient(0deg, #427a7e 0%, #7fe0e7 100%);
}

video {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  filter: sepia(1) saturate(1.5) hue-rotate(-225deg) brightness(0.75);
  /* filter: brightness(0.8); */
}

/* Needed to be 100% for carousel img to properly size */
.slick-list,
.slick-track,
.slick-slide {
  height: 100%;
}
.carousel img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.slick-prev,
.slick-next {
  height: 80px;
  width: 40px;
}
.slick-prev {
  font-size: 3em;
  z-index: 1;
  left: 15px;
}
.slick-next {
  right: 15px;
}
.slick-dots {
  bottom: 20px;
}
.slick-dots li button {
  width: 14px;
  height: 14px;
  border: 2px solid #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
}
.slick-dots li button::before {
  content: "";
}
.slick-dots li.slick-active button {
  background-color: #fff;
}
.slick-prev::before,
.slick-next::before {
  position: absolute;
  font-size: 60px;
  top: 0;
  text-shadow: 0 0 6px rgba(0, 0, 0, 0.6);
}
.slick-next::before {
  content: "⟩";
  left: 0;
}
.slick-prev::before {
  content: "⟨";
}
.slick-dotted.slick-slider {
  margin-bottom: 0px;
}

.ul-list {
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  -webkit-box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
.ul-list h4 {
  text-align: center;
}

.ul-list h4 a {
  padding: 0.5em;
  display: block;
  transition: color 200ms ease-in-out;
}
.ul-list h4 a:hover {
  color: #14b8ca;
}
.insurance-note {
  font-size: 12px;
  color: #757575;
}
.carousel-action {
  margin: 50px 0;
  border-left: 1px solid #14b8ca;
  border-right: 1px solid #14b8ca;
}
.carousel-action > .slick-prev {
  left: -40px;
}
.carousel-action > .slick-next {
  right: -40px;
}
.carousel-action .slick-dots {
  bottom: -60px;
}
@media (min-width: 768px) {
  .carousel-action .slick-dots {
    bottom: -40px;
  }
}

.carousel-action .slick-dots li button {
  width: 12px;
  height: 12px;
  border: 1px solid #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.6);
}
.carousel-action .slick-dots li button::before {
  content: "";
}
.carousel-action .slick-dots li.slick-active button {
  background-color: #fff;
}
.carousel-action .slick-slide .action-image {
  opacity: 0.7;
  box-sizing: content-box;
  height: 200px;
  background-color: white;
  padding: 5px;
  margin: 15px 10px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
  transition: opacity 700ms ease-in-out, transform 500ms ease-in-out;
}

/* FIX FOR LAST TO FIRST SLIDE TRANSITION BUG */
/* slide when not active/center */
.carousel-action
  .slick-slide[aria-hidden="true"]:not(.slick-cloned)
  ~ .slick-cloned[aria-hidden="true"]
  .action-image {
  opacity: 0.7;
  transform: scale(1);
}

/* slide when active/center */
.carousel-action
  .slick-slide[aria-hidden="true"]:not([tabindex="-1"])
  + .slick-cloned[aria-hidden="true"]
  .action-image {
  opacity: 1;
  transform: scale(1.1);
}

@media (min-width: 720px) {
  .carousel-action .slick-slide .action-image {
    height: 300px;
    padding: 10px;
    margin: 30px 20px;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.12);
  }
}
.carousel-action .slick-center .action-image {
  opacity: 1;
  transform: scale(1.1);
  box-shadow: 0 0 4px 1px #14b8ca66;
}

section {
  padding-top: 40px;
  padding-bottom: 40px;
  /* padding-top: 100px; */
  /* margin-top: -40px; */
}
section p {
  color: #1d2b36;
  /* color: #8b8989; */
  /* color: rgba(41, 41, 41); */
  /* color: #545454; */
}
/* section img:not(.flag-icon) { */
section .section-icon {
  display: block;
}
.flag-icon {
  width: 18px;
  height: 18px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.15);
}
.languages span {
  font-variant: all-petite-caps;
  /* font-size: 1.2em; */
}
.lang-tagalog {
  font-family: "tagalog";
}

.info-photo {
  width: 100%;
  background-color: #fff;
  padding: 8px;
  margin: 5px 0;
  /* margin-bottom: 30px; */
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
}
.dark {
  background-color: #f5f8fb;
}

.contact-info img {
  display: block;
  margin: 0 auto 20px auto;
}

.contact-info .storefront {
  width: 100%;
  background-color: #fff;
  padding: 5px;
  margin-top: 20px;
  margin-bottom: 30px;
  box-shadow: 0 0 4px 1px rgba(0, 0, 0, 0.12);
}

.therapist--headshot {
  /* display: inline; */
  border-bottom: 2px solid #14b8ca;
  margin-bottom: 30px;
}

/* Start Form */
form {
  padding: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 5px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
form img {
  display: block;
  margin: 0px auto 20px auto;
}
.form-group {
  position: relative;
  height: 3.5em;
}

.form-group--icon,
.form-group--label,
.form-group--input {
  color: #757575;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 200ms ease-in-out;
  transition-property: color tranform;
}
.form-group--text-area label,
.form-group--text-area i {
  top: 20%;
}
.form-group--icon {
  font-size: 1.5em;
}
.form-group--label {
  transform-origin: left;
  left: 2em;
}
.form-group--input {
  color: initial;
  width: calc(100% - 2em);
  margin-left: 2em;
  border: 0;
  border-bottom: 1px solid #7c7c7d;
}
.form-group--text-area {
  height: 8.5em;
}
textarea.form-group--input {
  resize: none;
  height: 7em;
}
.form-group--input:focus {
  outline: none;
  border-bottom: 1px solid #14b8ca;
}
.form-group--input:focus ~ label,
.form-group--input:not(:placeholder-shown) ~ label {
  color: #14b8ca;
  transform: translateY(-130%) scale(0.8);
}
.form-group--input:focus ~ i,
.form-group--input:not(:placeholder-shown) ~ i {
  color: #14b8ca;
  transform: translateY(-52%) scale(1.1);
}
.form-group--input:focus ~ i {
  color: #14b8ca;
}
.btn-default {
  color: #fff;
  background-color: #1292a0;
  box-shadow: 0 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.btn-default:focus,
.btn-default:hover {
  color: #fff;
  background-color: #14b8ca;
  /* box-shadow: none; */
}

.btn-default:active {
  color: #fff;
  background-color: #067b88 !important;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}
/* End Form */

.contact-links a span {
  color: #067b88;
}
.contact-links a:hover {
  color: inherit;
}
.contact-links a:hover span {
  color: #14b8ca;
}
.mapouter {
  position: relative;
  text-align: center;
  padding: 0;
}
.gmap_canvas {
  overflow: hidden;
  background: none !important;
  background-color: #14b8ca;
  padding-top: 80%;
}
.mapouter .gmap_canvas iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}
@media (min-width: 500px) {
  .gmap_canvas {
    padding: 0;
  }
  .mapouter .gmap_canvas iframe {
    position: relative;
    height: 400px;
    width: 100%;
  }
}

footer {
  color: #fff;
  background-color: #333333;
  padding: 25px 0;
}
.social-links ul {
  list-style-type: none;
  margin-bottom: 20px;
}
.social-links ul li {
  display: flex;
  width: 40px;
  height: 40px;
  background-color: grey;
  border-radius: 50%;
  margin: 0 10px;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  transition: background-color 300ms ease-in-out;
}
.social-links ul li a {
  text-decoration: none;
  color: inherit;
}
.social-links ul li:hover {
  cursor: pointer;
  background-color: #14b8ca;
}
.copyright {
  font-size: 10px;
  font-weight: 300;
  /* font-variant-caps: petite-caps; */
  color: grey;
}
.copyright a {
  color: inherit;
  text-decoration: none;
  transition: color 300ms ease-in-out;
}
.copyright a:hover {
  color: #14b8ca;
}
.back-to-top {
  /* color: #6d6f70; */
  color: #1292a0;
  font-size: 3em;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  /* display: none; */
  transform: translateY(90px);
  transition: transform 300ms ease-in-out;
  text-shadow: 0px 1px 3px rgba(0, 0, 0, 0.6);
}
.back-to-top:hover {
  color: #14b8ca;
}
.back-to-top.show {
  transform: translateY(0px);
}
.review-cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.rv--box {
  position: relative;
  padding: 20px;
  min-height: 223px;
  max-width: 400px;
  border: 1px solid #ededed;
  border-radius: 4px;
  background-color: #fafafa;
  margin-bottom: 20px;
}

.rv-avatar {
  object-fit: cover;
  width: 56px;
  height: 56px;
  margin-right: 10px;
  border-radius: 50%;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 4px rgba(0, 0, 0, 0.24);
}
.rv-name {
  display: block;
}
.rv--stars {
  background-image: url("./assets/stars.png");
  width: 108px;
  height: 20px;
}
.rv--google-stars {
  background-image: url("./assets/google-stars.png");
  width: 108px;
  height: 20px;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .rv--stars {
    background: url("./assets/stars@2.png") no-repeat top left;
    background-size: 176px 680px;
  }
  .rv--google-stars {
    background: url("./assets/google-stars@2x.png") no-repeat top left;
    background-size: 176px 680px;
  }
}
.rating-0 {
  background-position: 0px -320px;
}
.rating-1 {
  background-position: 0px -360px;
}
.rating-2 {
  background-position: 0px -400px;
}
.rating-3 {
  background-position: 0px -440px;
}
.rating-4 {
  background-position: 0px -480px;
}
.rating-5 {
  background-position: 0px -500px;
}
.rv--badge {
  position: absolute;
  height: 32px;
  top: 24px;
  right: 24px;
}
.rv--badge img {
  height: 100%;
}
.rv-review-date {
  font-size: 12px;
}
.rv--head {
  margin-bottom: 1.2em;
}
.rv--content {
  font-size: 14px;
  line-height: 1.3em;
  height: 100px;
  overflow-y: auto;
}
.rv--content--text {
  width: 100%;
}
.rv--content::-webkit-scrollbar {
  width: 4px;
}
.rv--content::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5);
}
.rv--content::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.modal-title {
  color: #1292a0;
}
.modal-footer .btn {
  background-color: #1292a0;
}
.modal-footer .btn:hover {
  background-color: #14b8ca;
}
